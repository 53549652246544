@import '../../../scss/variables';
@import '../../../scss/mixins.scss';

.about-wrapper {
  @include page-content-wrapper;
  // overflow-y: auto;
  //margin-top: 14px;
  margin-bottom: 14px;
  //padding: $space-m $space-l;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  background: #fff;

  .content-wrapper {
    padding-bottom: 0 !important;
  }

  .avoid-break {
    break-inside: avoid-column;
  }

  @media only screen and (min-width: 576px) {
    .dtb-long-text {
      column-count: 1;
      column-gap: 40px;
      column-rule-style: solid;
      column-rule-width: 1px;
      column-rule-color: #ccc;
      background: none;
      border: none;
      box-shadow: none;
    }
    .dtb-imprint-text-layout {
      column-gap: 60px;
    }
  }

  @media only screen and (min-width: 576px) {
    .dtb-long-text {
      column-count: 1;
    }
  }

  @media only screen and (min-width: 768px) {
    .dtb-long-text {
      column-count: 1;
    }
  }

  @media only screen and (min-width: 992px) {
    .dtb-long-text {
      column-count: 2;
    }
  }

  @media only screen and (min-width: 1200px) {
    .dtb-long-text {
      column-count: 2;
    }
  }

  @media only screen and (min-width: 1400px) {
    .dtb-long-text {
      column-count: 3;
    }
  }

  .wrapper-image-list {
    margin-top: $space-l;
    list-style: none;

    .img-item {
      display: block;
      max-width: 90px;
      height: auto;
    }
  }
}

.wrapper-image-list {
  margin-top: 1.5rem;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.ant-row-flex {
  display: flex;
  flex-flow: row wrap;
}

.about-wrapper .wrapper-image-list .img-item {
  display: block;
  max-width: 90px;
  height: auto;
}
