@import './../abstract/mixins/breakpoints.scss';

.ant-list-item-meta {
  margin-top: 14px;

  .ant-list-item-meta-content {
    display: flex;
    align-items: center;
    line-height: 32px;
    width: 100%;
    height: 100%;

    .ant-list-item-meta-title {
      width: 100%;
      height: 32px;
      padding-top: 6px;
    }
  }
}
