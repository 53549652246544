.ant-tabs {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  height: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;

  // antd  tab fix height scrooll + safari fix
  .ant-tabs-content-animated,
  .ant-tabs-content-holder {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    overflow-y: auto !important;
    max-height: 100%;
    height: 100%;
    max-width: 100%;
    width: 100%;
    -webkit-flex: 1 1 auto;
    flex: 1 1 0;
  }

  .tab-wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    height: 100%;
    align-items: stretch;
    width: 100%;

    .ant-tabs-bar {
      width: 100%;
      height: 100%;
      margin: 0 0 0px 0;
      padding: 0;
    }

    .ant-tabs-content {
      width: 100%;
      min-height: 100%;
    }
  }
}
