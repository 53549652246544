.ant-drawer-body {
  background: #f0f2f5 !important;
  .ant-card {
    margin-top: 14px;
    .ant-card-head {
      margin-top: 10px;
    }
  }
  .ant-menu {
    background: none;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .ant-menu li:hover,
  .ant-menu-item-active {
    background: none;
  }

  .ant-menu-item-active {
    color: #000 !important;
  }
}
