// antd breakpoints
$breakpoints: (
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
  'xxl': 1600px
) !default;

@mixin breakpoint($breakpoint, $direction: $media-direction) {
  // check value exist in sass map
  @if map-has-key($breakpoints, $breakpoint) {
    // output
    @media (#{$direction}-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }

  // check unit exist. if not use px.
  @else if unitless($breakpoint) {
    $breakpoint: $breakpoint + 0px;

    // output
    @media (#{$direction}-width: $breakpoint) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
