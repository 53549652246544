@media only screen and (min-width: 768px) {
  /**
   * @description config tab need full screen layer  
  **/
  .ant-tabs-content {
    display: flex;
    width: 100%;
    height: 100%;
  }
  .dtb-content {
    .ant-tabs-tabpane {
      width: 100%;
      height: 100%;
      min-height: 100%;
      //overflow-y: scroll;
      overflow: hidden;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      display: flex;
      justify-content: space-between;
    }
    .ant-tabs {
      .ant-tabs-nav {
        margin: 0 0 0px 0;
      }
      .ant-tabs-tabpane .ant-tabs-nav {
        margin: 0 0 0px 0;
      }
    }
  }
}
