@import './../abstract/mixins/grid.scss';
@import './../abstract/mixins/flexWithHeight.scss';
@import './../abstract/mixins/breakpoints.scss';

html,
body {
  @include grid(1fr, 1fr);
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  box-sizing: border-box;

  #root {
    overflow: hidden;
    background: none;
  }

  .dtb-main-content {
    margin: 0;
    box-sizing: border-box;
    //background: beige;
    overflow-y: auto;
    height: 100%;
    margin: 0px;
    padding: 0px 14px 0px 14px;
    box-sizing: border-box;
    @include breakpoint('xl', 'min') {
      padding: 0;
      margin: 0px 10px;
    }
  }

  .dtb-layout,
  .dtb-main-content,
  .ant-layout {
    background: none !important;
    width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 100%;
  }

  .dtb-layout {
    // @include grid-child(1, 2);
    //@include grid(1fr, 60px 1fr);
    //background: red !important;
    display: grid;
    //grid-template-columns: 1fr;
    grid-template-rows: 60px;
    // safari bug width
    max-width: 100%;
    max-height: 100%;
    min-height: 0;
    //padding-bottom: 60px !important;
    overflow-y: hidden;
    //display: contents;

    .dtb-header {
      @include grid-child(1, 1);
      height: 60px;
      width: 100%;
    }

    .dtb-main-content {
      @include grid-child(1, 2);
      @include flexWithHeight(column);
    }

    .dtb-main-content-scroll {
      @include grid-child(1, 2);
      @include flexWithHeight(column);
    }
  }
}
