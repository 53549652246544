@import './../abstract/mixins/breakpoints.scss';

.ant-table-expanded-row-fixed {
  padding: 0 !important;
  margin: 0;

  .anticon {
    color: #fff;
  }

  .dtb-warnings-grid {
    margin: 0;
    padding: 0;
    background-color: #f76a6a;

    .dtb-warning-icon {
      margin: 10px;
      display: inline-block;
      float: left;
    }
    .dtb-warnings {
      color: #fff;
      border-bottom: solid 1px #ccc;
    }
    .dtb-warning-baneer-close {
      float: right;
      margin: 10px;
    }

    span.ant-typography p {
      padding-top: 5px;
      margin-bottom: 0;
      text-overflow: ellipsis;
      width: 60%;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @include breakpoint('md', 'min') {
      span.ant-typography p {
        width: 80%;
      }
    }
  }
}
// table mobil
@include breakpoint('md', 'max') {
  /*
  .ant-table-body,
  .ant-table-cell,
  .ant-table-expanded-row-fixed,
  .ant-table .ant-table-expanded-row-fixed {
    width: 100% !important;
    max-width: 100%;
  }

  .ant-table-expanded-row td {
    padding: 0px 0px !important;
    overflow-wrap: break-word;
  }

  .ant-table-expanded-row td {
    position: relative;
    padding: 0px 0px !important;
    overflow-wrap: break-word;
    overflow: hidden;
  }

  .dtb-warnings-grid {
    margin: 0;
    padding: 0;
    //background-color: green;
    height: 70px;
    .dtb-warnings {
      margin: 15px 5px !important;
    }
    .dtb-warning-icon {
      margin: 10px;
      display: inline-block;
    }
    .dtb-warnings {
      color: #fff;
      border-bottom: solid 1px #ccc;
    }
    .dtb-warning-baneer-close {
      float: right;
      margin: 10px;
    }
  }
  */
}
