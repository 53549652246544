@import '~antd/dist/antd';
//@import '~design-system/dist/variables';
@import './scss/mixins';
@import './scss/customFonts';
// main layout
@import './scss/design-system/layout/main-grid.scss';
@import './scss/design-system/layout/page-layout.scss';
@import './scss/design-system/layout/content-grid-sidebar.scss';
@import './scss/design-system/layout/content-grid-tab';
@import './scss/design-system/layout/tab-layout';
// components
@import './scss/design-system/components/dtb-init-loader.scss';
@import './scss/design-system/components/dtb-header.scss';
@import './scss/design-system/components/dtb-detail-sidebar-menu.scss';
@import './scss/design-system/components/dtb-detail-header.scss';
@import './scss/design-system/components/dtb-detail-sidebar';
@import './scss/design-system/components/ant-tab.scss';
@import './scss/design-system/components/ant-card.scss';
@import './scss/design-system/components/ant-skeleton.scss';
@import './scss/design-system/components/dtb-warning-banner.scss';
@import './scss/design-system/components/dtb-mobil-offcanvas.scss';

.ant-btn-primary {
  .anticon {
    color: #fff;
  }
}
// organisms
.ant-drawer-body {
  padding: 14px !important;
  .ant-card:last-child {
    margin-bottom: 0 !important;
  }
}

// medikation nicht einnahme 100% umbrochen verhindern
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-left: auto;
  white-space: nowrap;
}

// messaging spiner
.dtb-chat-detail-content {
  justify-content: center;
}

html,
body {
  background: #f0f2f5 !important;
}
.ant-drawer-body {
  background: #f0f2f5 !important;
  .ant-card {
    margin-top: 14px;
    .ant-card-head {
      margin-top: 10px;
    }
  }
  .ant-menu {
    background: none;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .ant-menu li:hover,
  .ant-menu-item-active {
    background: none;
  }

  .ant-menu-item-active {
    color: #000 !important;
  }
}

#root,
html,
body {
  max-height: 100%;
}

/*
.about-wrapper .ant-row,
.about-wrapper .ant-col {
  width: 100%;
}
*/

.about-wrapper {
  max-width: 100%;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-height: 100%;
  overflow: hidden;
  min-height: 0;
  // background: deeppink;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  overflow-y: auto !important;
  max-height: 100%;
  height: 100%;
  flex: 1 1 auto !important;
  -webkit-flex: 1 1 0 !important;
}

.ant-layout {
  height: 100%;
  max-height: 100%;
}

// collapse headder date
.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-header-text {
  display: inline;
  text-align: left;
}
