// only need it in monitoring
.wrapper-tab-content {
  min-height: 100%;
  overflow-y: auto;
  width: 100%;
  max-width: 100%;
}

// messaging badge
.ant-tabs-tab {
  .ant-badge-not-a-wrapper {
    position: relative;
    top: -8px;
    left: 0px;
  }
}
