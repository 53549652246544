@import './../abstract/mixins/grid.scss';
@import './../abstract/mixins/flexWithHeight.scss';
@import './../abstract/mixins/breakpoints.scss';

.dtb-page-content-layout {
  @include flexWithHeight(row);
  flex-wrap: wrap;
  -webkit-flex-wrap: nowrap;
  -webkit-flex: 1 1 auto; // safari fix
  flex: 1 1 auto; // safari fix
  flex: 1 1 0; // safari fix
  background: none;
  padding: 0px !important;
  max-width: 100%;
  height: 100%;
  //background: deeppink;

  @include breakpoint('md', 'min') {
    padding-top: 14px !important;
  }
  @include breakpoint('xl', 'min') {
    padding: 14px 14px 0px 0px !important;
  }
  // ---------------
  // MAIN NAV CONTENT (LEFT SIDE)
  // ---------------
  .dtb-page-content-layout-nav {
    display: flex;
    flex-direction: row;
    margin-bottom: 14px;

    @include breakpoint('xl', 'min') {
      // background: darkblue;
      padding-bottom: 14px;
    }

    .dtb-inner-content-nav {
      display: none;
    }

    @include breakpoint('xl', 'min') {
      .dtb-inner-content-nav {
        display: block;
        width: 100px !important;
        min-width: 200px;
        min-height: 0;
        overflow: hidden;
        // background-color: aqua;

        .ant-menu {
          @include flexWithHeight(row);
          overflow-y: auto !important;
          overflow-x: hidden;
          min-height: 0;
          background: none;
          border: none;
        }
      }
    }
  }

  // ---------------
  // MAIN CONTENT (RIGHT SIDE)
  // ---------------
  .dtb-page-content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    flex-grow: 1;
    height: 100%;
    margin-right: 0px;
    @include breakpoint('xl', 'min') {
      margin-right: 10px;
    }

    // safari fix
    .dtb-main-content-wrapper {
      display: -webkit-flex;
      flex: 1 1 auto;
      -webkit-flex: 1 1 0;
      flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      height: 100%;
      max-height: 100%;
      overflow: 'hidden';
    }
  }
}
